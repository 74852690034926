<template>
  <div class="diagrambox">
    <div class="diagrambox-center">

      <!-- <el-date-picker v-model="value3" type="year" placeholder="请选择时间">
        </el-date-picker> -->
      <div class="steps">
        <div class="stepsText" ref="scrollDiv" @mousewheel="MouseWheel($event)">
          <p v-for="(item, index) in steps" :key="index">
            <span class="text" :class="{ active: stepsColor == index }"
              @click="clickSteps(item, index)">{{ item }}</span>
            <span class="icon" :class="{ active: stepsColor == index }"></span>
            <i class="line" :class="{ active: stepsColor == index }"></i>
          </p>
        </div>
      </div>
      <div class="navigation">
        <el-tree ref="leftTree" node-key="id" default-expand-all :data="diagramtreelist"
          :props="defaultProps" show-checkbox check-strictly @check-change="handleNodeClick">
        </el-tree>
      </div>

    </div>
  </div>
</template>

<script>
import api from '../visualization/api'

export default {
  // props: ['op', 'diogramid'],
  props: ['treeparentid', 'op', 'radarnum'],
  components: {
  },
  data () {
    return {
      ceshistyle: {},
      isAction: -1,
      diagramtreelist: [],
      value3: '',
      defaultProps: {
        children: 'children',
        label: 'categoryName'
      },
      steps: [new Date().getFullYear()],
      stepsColor: 0,
      timewire: new Date().getFullYear(),
      initialtime: new Date().getFullYear(),
      treeid: null,
      num: ''
    }
  },
  computed: {},
  watch: {
    treeparentid: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        // console.log('ceshichuanzhi')
        if (newvalue) {
          this.$nextTick(() => {
            // console.log(newvalue, 'tree')
            this.treeid = newvalue
            this.pageobserytree(newvalue, new Date().getFullYear())
            // this.diagramtreelist = newvalue
          })
        }
      }
    },
    radarnum: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        // console.log(newvalue, lodvalue, 'radarnum')
        this.$nextTick(() => {
          if (newvalue !== 0) {
            this.num = ''
            this.$refs.leftTree.setCheckedNodes([])
            // console.log(this.num, '-==-=--==')
          }
        })
      }
    }

  },
  methods: {
    MouseWheel (e) {
      const eventDelta = -e.wheelDelta || -e.deltaY * 40
      const scrollDiv = this.$refs.scrollDiv
      scrollDiv.scrollLeft = scrollDiv.scrollLeft + eventDelta / 2
    },
    timeline () {
      for (var i = 1; i <= 30; i++) {
        this.initialtime -= 1
        this.steps.push(this.initialtime)
      }
    },
    clickSteps (ele, leve) {
      this.stepsColor = leve
      this.timewire = ele
      this.pageobserytree(this.treeid, ele)
    },
    // 树形点击
    handleNodeClick (data, checked) {
      // 切换逻辑
      if (this.num !== data.id) {
        // console.log(data.id, '切换逻辑', this.num, '=>', data.id)
        if (checked) {
          this.num = data.id
          this.$refs.leftTree.setCheckedNodes([data])
          this.op.GetCutlineHeler().Load(data.id, this.timewire, (res, data) => {
            if (res) {
              // console.log(data, '90')
              this.$store.commit('zsqy/setlegenddata', data)
            }
          })
        } else {
          this.$store.commit('zsqy/setlegenddata', [])
        }
      } else {
        // 自身逻辑
        // console.log(data.id, '自身逻辑')0
        if (checked) {
          this.num = data.id
          this.$refs.leftTree.setCheckedNodes([data])
          this.op.GetCutlineHeler().Load(data.id, this.timewire, (res, data) => {
            if (res) {
              // console.log(data, '90')
              this.$store.commit('zsqy/setlegenddata', data)
            }
          })
        } else {
          this.op.GetCutlineHeler().Dispose()
          this.$store.commit('zsqy/setlegenddata', [])
        }
      }
    },
    colsetree () {
      // console.log('guanbi')
      this.op.GetCutlineHeler().Dispose()
    },
    pageobserytree (id, time) {
      const obj = {
        id: id,
        year: time
      }
      api.pageobsery(obj).then(res => {
        this.diagramtreelist = res.data
      })
    }

  },
  created () {
    this.timeline()
  },
  destroyed () {
    // console.log('xiaohui11 ')
  }
}
</script>
<style lang="less" scoped>
.diagrambox {
  width: 100%;
  height: 100%;

  // overflow: hidden;
  background: transparent;
  /deep/.el-input__inner {
    background-color: rgba(230, 252, 255, 0.3);
    border: none;
    color: #bfdafd;
    font-size: 12px;
    border-radius: 0px;
  }
  &-center {
    width: 100%;
    height: 100%;
    // background: #83b4ef;
    margin: 20px 0 0 3%;
    // overflow: hidden;
    .steps {
      width: auto;
      height: auto;
      z-index: 5;
      cursor: pointer;
      // background: red;
      // overflow-x: scroll;
      .stepsText {
        width: 100%;
        // height: 60px;
        // display: flex;
        white-space: nowrap;
        overflow: hidden;
        display: -webkit-box;

        // background: blue;
        p {
          width: 60px;
          height: 45px;
          // background: yellow;
          font-size: 16px;
          cursor: default;
          display: inline;
          padding: 0 15px;
          float: left;
          position: relative;
          span.text {
            color: #999;
          }
          span.text.active {
            color: #fff;
          }
          span.icon {
            display: inline-block;
            width: 8px;
            height: 8px;
            cursor: default;
            position: absolute;
            left: 10px;
            top: 32px;
            border-radius: 50%;
            z-index: 1;
            background: #999;
          }
          span.icon.active {
            background: #fff;
          }
          i.line {
            display: inline-block;
            position: absolute;
            left: 10px;
            top: 35.5px;
            width: 100%;
            height: 1px;
            background: #999;
          }
          i.line.active {
            background: #fff;
          }
        }
      }
      .stepsText:hover {
        overflow-x: scroll;
      }
      /* 设置滚动条的样式 */
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background: transparent;
        // background: red;
      }
      /* 滚动条滑块 */
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(230, 252, 255, 0.5);
        // background: red;
        //  background: rgba(230, 252, 255, 0.5);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }
      // .stepsText:hover {
      //   overflow-x: scroll;
      // }
    }

    .navigation {
      width: 100%;
      height: 90%;
      // background: #bfdafd;
      margin-top: 5%;
      overflow-y: auto;
      /deep/.el-tree-node {
        .is-leaf + .el-checkbox .el-checkbox__inner {
          display: inline-block;
        }
        .el-checkbox .el-checkbox__inner {
          display: none;
        }
      }
      //树形结构
      /deep/.el-tree {
        background-color: transparent;
        color: #fff;
      } //背景颜色
      /deep/.el-tree-node__content {
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      } //鼠标划入颜色
      /deep/.el-tree-node.is-current > .el-tree-node__content {
        background-color: transparent !important;
      } //点击背景颜色
      /deep/.el-tree-node:focus > .el-tree-node__content {
        background-color: transparent !important;
      } //点击父级背景颜色
      /deep/.el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #878987;
        border-color: #878987;
      }
    }
  }
}
</style>
