import { get, post } from 'common/request'

export default {
  // // 菜单查询
  // MenufindTree: path => zsqyget('/LayersMenu/findTree', path),
  // // 菜单父类id查子类
  // MenufindList: path => zsqyget('/LayersMenu/findList', path),
  // // id查询图层
  // FilefindByPage: path => zsqypost('/layersFile/findLsit', path),
  // // 查询水环境统计列表
  // WaterqualityPage: path => zsqypost('/Waterquality/findLsit', path),
  // 查询负责人接口
  UserTreePage: path => get('/user/query', path),
  // 查询协作人员接口
  dutyTreePage: path => get('/patrol-safety-grid/duty/tree', path),
  // 查询巡护
  thirdListPage: path => get('/patrol-task-plan/thirdList', path),
  // 查询结果类型
  resultStatisticsPage: path => get('/patrol-polling/resultStatistics', path),
  // 工单添加
  deviceAdd: path => post('/patrol-polling/deviceAdd', path),
  // 工单详情
  pagethirdInfo: path => get('/patrol-polling/thirdInfo', path),
  // 多样性菜单
  pagediversity: path => get('/observation/category/getTree', path),
  // 图例菜单
  pageobsery: path => get('/observationGroupCategory/getTree', path),
  // 保护区资源概况
  pageStatistics: path => get('/observationCategoryStatistics/list', path),
  // 样地样方
  pagelistThird: path => get('/observation/task/listThird', path),
  // 科教宣传
  listThird: path => get('/science/listThird', path),
  // 无人机历史
  pageflightFecordList: path => get('/flight/flightFecordList', path),
  // 观测路线
  pagetasklistThird: path => get('/observation/task/thirdList', path)

}
